html, body, #root, .app {
  width: 100%;
  height: 100%;
  margin: 0;
}

.message {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
  background-color: black;
  color: white;
  font-size: 22px;

}
